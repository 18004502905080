import Vue from 'vue'
import Router from 'vue-router'


import Layout from '@/layout'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
export const constantRoutes = [
    {
      path: '/login',
      component: () => import('@/views/login/index'),
      hidden: true
    },
    {
        path:'/',
        component:Layout,
        redirect:'/home',
        children:[
            {
                path:'home',
                name:'home',
                component:() => import('@/views/home/index'),
                meta:{title:'许可证总览',requireAuth:true}
            }
        ]
    },
    {
        path:'/jihuomaguanli',
        component:Layout,
        redirect:'/jihuomaguanli/jihuomatable',
        name:'jihuomaguanli',
        meta:{title:'激活码管理'},
        children:[
            {
                path:'jihuomatable',
                name:'jihuomatable',
                component:() => import('@/views/jihuomaguanli/jihuomatable'),
                meta:{
                    title:'激活码管理列表',activeMenu:'/jihuomaguanli'
                },
                hidden:true
            }
        ]
    },
    {
        path:'/quanxianguanli',
        component:Layout,
        redirect:'/quanxianguanli/quanxiantable',
        name:'quanxianguanli',
        meta:{
            title:'权限管理'
        },
        children:[
            {
                path:'quanxiantable',
                name:'quanxiantable',
                component:() => import('@/views/quanxianguanli/quanxiantable'),
                meta:{
                    title:'权限管理列表',activeMenu:'/quanxianguanli'
                },
                hidden:true
            }
        ]
    }
]

const createRouter = () => new Router({
    mode: 'history', // require service support // base:'/test',// base:'/',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })
  
const router = createRouter()

router.beforeEach((to, from, next) => {
      let token = localStorage.getItem('token');
      if (to.path == "/login") {
        // console.log('第一步')
        next()
      } else {
        // console.log('第二步')
        if (token) {
        //    console.log('第三步')
                next()
        } else {
        //   console.log('第四步')
            next({
                path: "/login"
             })
        }
      }
    })
export default router

