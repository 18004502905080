<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// var def;
import {
  verifylogin,
  ajaxLogin,
  login,
  loginWeb,
  loginWebAjax,
  verify,
  getProduct
} from "@/api/user";
import $ from "jquery";
export default {
  name: "App",
  data() {
    return {
      token: "",
      def: "",
    };
  },
  created() {
    document.title = "星云互联许可证平台";
    let _this = this;
    var token = _this.getQueryVariable("token");
    if (token === "false") {
      return;
    } else if (token) {
      _this.Setcookie("token", token);
      verify(token).then((res) => {
        if (res.code == "200") {
          localStorage.setItem("token", token);
          localStorage.setItem("userId", res.data.userId);
          _this.Setcookie("token", token);
          _this.$router.push("/home");
        } else {
          _this.$router.push("/login");
          _this.$message.error(res.msg);
        }
      });
    } else if (!token) {
      verifylogin().then((res) => {
        if (res.code == "401") {
          window.location.href =
            Config.userCenter + "/login/web?mode=1&redirectUrl=" + Config.url;
        } else {
          //存用户信息
          localStorage.setItem("userId", res.data.userId);
          _this.$router.push("/home");
        }
      });
    }
  },

  beforeCreate() {
    // 1.截取路径上的token，三种情况：fasle,有值,无值，如果是fasle,直接进入登录页
    // 2.判断有无token，如果有token,直接进入第3步，如果没有token，进入第4步
    // 3.验证token,验证通过后进入首页，并存入token
    // 4.发送hasLogin请求，如果返回false,进入第5步，否则，进入第3步
    // 5.跳转页面到sso验证页面，两种情况，false与其他值，如果是false，进入登录页，如果有值，进入第3步
  },
  mounted(){
    this.queryProduct();
  },
  methods: {
    queryProduct(){
      getProduct().then((res) => {
        if(res.status == 0){
          if(res.result.length == 0){
            this.$message({
              message:'此账户无权限!',
              duration:0,
              type:'error'
            })
          }else{
            return;
          }
        }
      })
    },
    getQueryVariable(key) {
      var result = {};
      var paramStr = encodeURI(window.document.location.search);
      if (paramStr) {
        paramStr = paramStr.substring(1);
        var params = paramStr.split("&");
        for (var p = 0; p < params.length; p++) {
          result[params[p].split("=")[0]] = unescape(params[p].split("=")[1]);
        }
      }
      return result[key];
    },
    Setcookie(name, value) {
      //设置名称为name,值为value的Cookie
      var expdate = new Date(); //初始化时间
      expdate.setTime(expdate.getTime() + 365 * 24 * 60 * 60 * 1000); //设置过期时间，单位毫秒，这里以一年过期时间为例
      document.cookie =
        name + "=" + value + ";expires=" + expdate.toGMTString() + ";path=/";

      //时间默认为当前会话可以不要，但路径要填写，因为JS的默认路径是当前页，如果不填，此cookie只在当前页面生效！
    },
    getCookie(name) {
      //document.cookie.setPath("/");
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) {
        return unescape(arr[2]);
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  height: 100%;
  width: 100%;
  overflow: auto;
}
</style>
