export default {
	url20001: "/hasLogin",  //本地联调 玮骏 接口地址
    url200:'/hasLogin',
	url201:'/stat',
	url202:'/activation',
	url203:'/permission',
	url100:'/usercenter',
	product:'/product',
	verify:'/verify',
	url204:'/logout',
	login:'/login',
    //url200:'auth',
	test:'http://192.168.1.66:8082/test',
	url:'https://auth.nebula-link.com/login',
	urlHome:'https://auth.nebula-link.com/home',
	userCenter:'https://auth.nebula-link.com/api/usercenter',
	urlpassport:"http://192.168.1.209:8081/passport/bgdata",//passport登录地址
	appId:5,
	redirectUrl:"http://192.168.1.101:8080", //前端启动地址
	cqredirectUrl:"http://192.168.1.66:8081/", //前端启动地址
	pathurl: "http://192.168.1.209:8081/upload", //文件地址
}