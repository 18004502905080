module.exports = {

    title: 'NL许可证系统',
  
    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: true,
  
    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: true
  }
  