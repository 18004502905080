<template>
  <el-container>
    <el-header>
        <div class="logo"></div>
        <div class="headerContent">
          <span class="headerTitle">星云互联</span>
          <span class='verticalLine'> | </span>
          <span class="elHeaderTitle">许可证平台</span>
        </div>
        <span class="userName">{{username}}</span>
        <div class="signOut" @click="logout"></div>
    </el-header>
    <el-container>
        <el-aside class="aside" style="width:317px;">
        <el-menu router :default-active='$route.path'>
      <el-menu-item index="/home" @click="click">
        <template slot="title"><i class="el-icon-menu"></i>许可证总览</template>
      </el-menu-item>
      <el-menu-item index="/jihuomaguanli/jihuomatable" @click="click">
        <template slot="title"><i class="el-icon-key"></i>激活码管理</template>
      </el-menu-item>
      <el-menu-item index="/quanxianguanli/quanxiantable" @click="click">
        <template slot="title"><i class="el-icon-s-custom"></i>权限管理</template>
      </el-menu-item>
    </el-menu>
    </el-aside>
      <el-main><router-view /></el-main>
    </el-container>
  </el-container>
</template>

<style scoped>
     html,
     body,
    .el-container{
        padding: 0;
        margin: 0;
    }  
  .el-aside {
    color: #333;
  }
  .aside{
      background-color: #fff;
      border-right: 1px solid lightgray;
  }
  .el-menu{
      background-color: #fff;
      border-right:none
  }
  .el-main{
    padding-left: 94px;
    padding-right: 93px;
    padding-top: 32px;
    background: #D5D5D5;
    position: absolute;
    left: 317px;
    right: 0;
    top:88px;
    bottom:0;
  }
  .el-header{
      height: 88px!important;
      background-color: #233663;
      position: relative;
  }
  .logo{
    background: url('../assets/logo.png');
    background-size: 100% 100%;
    position: absolute;
    left: 58px;
    top: 18px;
    width: 168px;
    height: 53px;
  }
  .userName{
    font-size: 21px;
    color:#ffffff;
    position: absolute;
    top: 31px;
    right: 67px;
  }
  .signOut{
    background: url('../assets/signOut.png');
    background-size: 100% 100%;
    position: absolute;
    right: 30px;
    top: 38px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .el-icon-arrow-down:before {
    content: none!important;
  }
  .headerTitle{
      font-size: 24px;
      color:#4880FF
  }
  .verticalLine{
      color:#ffffff;
      font-size: 24px;
  }
  .elHeaderTitle{
      font-size: 24px;
      color:#ffffff;
  }
  .el-menu{
    margin-top: 15px;
  }
  .el-menu-item{
    color:#000000!important;
    font-size: 16px;
    padding-left: 70px!important;
  }
  .el-menu-item.is-active{
    background: url('../assets/darkBack.png');
    background-size: 100% 100%;
    width: 274.6px;
    height:60px;
    color: #ffffff!important;
  }
  .headerContent{
    position: absolute;
    top:28px;
    left:243px;
  }
</style>

<script>
import $ from 'jquery';
    export default {
        name:'Layout',
        data(){
          return{
            isChoosed:false,
            username:'',
          }
        },
        mounted(){
          let account = localStorage.getItem('account');
          let _this = this;
          _this.username = account;
          // _this.queryProduct();
        },
        methods:{
          click(){
            this.isChoosed = true
          },
          logout() {
            //await this.$store.dispatch('user/logout');
            // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
            let _this = this;
            let userId = localStorage.getItem('userId');
            let url = Config.url204 + '?userId='+userId;
            $.ajax({
              url:url,
              type:'GET',
              contentType:'application/json;charset=utf-8',
              data:JSON.stringify(),
              success:function(data){
                if(data.code === '200'){
                  localStorage.clear();
                  _this.$router.push('/login');
                  _this.$message.close();
                }
              }
            })
          }
        }
    }
</script>