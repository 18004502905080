import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import pagrowinfo from './modules/pagrowinfo'

import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)
const state = {
  scale: "0",
  ajaxUrl:""
}
const mutations = {
  setScale(state, scale) {
    state.scale = scale;
  },
  setAjaxUrl(state,ajaxUrl){
    state.ajaxUrl=ajaxUrl;
  }
}

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    pagrowinfo
  },
  plugins: [createPersistedState({
    // storage: window.sessionStorage,
    storage: window.localStorage,
    reducer(val) {
      return {
        // 只储存state中的user
        user: val.user,
        ajurl:val.state
      }
    }
  })],
  getters,
  state,
  mutations
})

export default store

