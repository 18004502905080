const getDefaultState = () => {
    return {
        rowInfo: ''
    }
}

const state = getDefaultState()

const mutations = {
    SET_ROWINFO: (state, rowInfo) => {
        state.rowInfo = rowInfo
        sessionStorage.setItem('rowInfo', rowInfo)
    }
}
const actions = {

}
export default {
    namespaced: true,// 用于在全局引用此文件里的方法时标识这一个的文件名
    state,
    mutations,
    actions
}
