import request from '@/utils/request'
// import { post } from 'jquery'
// import { Config } from '@/config'
// console.log(Config,'peizhiwenjian')
export function verifylogin() {
  return request({
    url: Config.url20001,
    method: 'get'
  })
}
export function verify(token){
  return request({
    url:Config.verify+'?token='+token,
    method:'get',
    headers:{
      'token':token
    }
  })
}
export function loginWebAjax(data){
  return request({
    url:Config.userCenter + '/login/webAjax',
    method:'post',
    data:data
  })
}
export function login() {
  return request({
    url: Config.url100+'/sso/login?redirectUrl='+Config.redirectUrl+'&appId='+Config.appId+'&device=Device_Windows&mode=1',
    method: 'get',
  })
}
export function logout() {
  return request({
    url: Config.url20001+'/sso/logout?redirectUrl='+Config.redirectUrl,
    method: 'get'
  })
}
export function getProduct(){
  return request({
      url:Config.product,
      method:'get',
  })
}