const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  useridlg: state => state.user.useridlg,
  rowInfo:state => state.pagrowinfo.rowInfo,
  roles:state => state.user.roles,
  rolelist:state => state.user.rolelist,
}
export default getters
