import axios from 'axios'
import {Message} from 'element-ui';
import store from '@/store'
import {getToken} from '@/utils/auth'

axios.defaults.withCredentials=true
axios.defaults.crossDomain=true
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
    baseURL:process.env.VUE_APP_BASE_API,
    withCredentials:true
})

service.interceptors.request.use(
    config => {
        if(store.getters.token){
            config.headers['token'] = getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        if(res.command === '401'){
            Message({
                message:res.message||'Error',
                type:'error',
                duration:1 * 1000
            })
            setTimeout(function(){store.dispatch('user/resetToken').then(() => {
                // window.location.href=Config.redirectUrl+'#login'
            })},2000)
            return Promise.reject(new Error(res.message || 'Error'))
        }else if(res.command === '403'){
            Message({
                message:res.message||'Error',
                type:'error',
                duration:3 * 1000
            })
        }else{
            return res
        }
    },
    error => {
        Message({
            message:'请求错误',
            type:'error',
            duration:3 * 1000
        })
        return Promise.reject(error)
    }
)

export default service