import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    useridlg: '',
    avatar: '',
    roles: [],
    rolelist:[]
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USERIDLG: (state, useridlg) => {
    state.useridlg = useridlg
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ROLELIST: (state, rolelist) => {
    state.rolelist = rolelist
  }
}

const actions = {
  // verifylogin({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     if (window.location.href != "") {  //http://192.168.1.105:8081/test/?token=false#/
  //       let modevalarr = window.location.href.split("?");
  //       let modeval = null
  //       if (modevalarr && modevalarr.length > 1) {
  //         let modevaltkarr = modevalarr[1].split("#");
  //         modeval = modevaltkarr[0].split("=");
  //       }
  //       if (modeval && modeval[0] == "token") {
  //         //getInfo
  //         let token = modeval[1]
  //         if (token && token != "false") {
  //           token = token.split("#")[0];
  //           commit('SET_TOKEN', token)
  //           setToken(token)
  //           // getInfo(token)
  //           // this.$router.push({ path: '/' })
  //         } else {
  //           // window.location.href=Config.redirectUrl+"#/login"
  //         }
  //       } else {
  //         verifylogin().then(response => {
  //           let resssolog = response.result;
  //           if (resssolog && typeof (resssolog) == "object") {
  //             let token = resssolog.token
  //             commit('SET_TOKEN', token)
  //             setToken(token)
  //             getInfo(token)
  //           }else if(resssolog==false){

  //           } else {
  //             if (window.location.href != "") {
  //               let modevalarr = window.location.href.split("?");
  //               let modeval = null;
  //               if (modevalarr.length > 1) {
  //                 modeval = modevalarr[1].split("=");
  //               }
  //               if (modeval && modeval[0] == "mode") {
  //                 //getInfo
  //               } else {
  //                 window.location.href = Config.urlpassport + '/sso/login?mode=1&redirectUrl=' + Config.redirectUrl + '&appId=' + Config.appId;
  //               }
  //             }
  //           }
  //           resolve(modeval)
  //         }).catch(error => {
  //           reject(error)
  //         })
  //       }
  //     }
  //   })
  // },
  // user login
  // login({ commit }, userInfo) {
  //   const { username, password } = userInfo
  //   return new Promise((resolve, reject) => {
  //     login({ queryParam: username, password: password, appId: Config.appId }).then(response => {
  //       if (response.status == 0) {
  //         let token = response.result.split("=")[1]
  //         commit('SET_TOKEN', token)
  //         setToken(token)
  //       }
  //       resolve(response)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token).then(response => {
  //       const { result } = response
  //       if (!result) {
  //         reject('验证失败，请重新登录.')
  //       }
  //       const { username, id,account,roleList } = result  //avatar
  //       commit('SET_ROLELIST', roleList);
  //       let name = username
  //       let useridlg = id
  //       //存储到sessionStorage
  //       let rowInfo={
  //         userId:id,
  //         account:account
  //       }
  //       sessionStorage.setItem('rowInfo',JSON.stringify(rowInfo));
  //       let avatar = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
  //       let routerList = [];
  //       let routermenu = result.pagePermissionTree.children;
  //       if (routermenu.length > 0) {
  //         // debugger
  //         let routers = routermenu;
  //         for (var i = 0; i < routers.length; i++) {
  //           let meta = {};
  //           meta.title = routers[i].title;
  //           meta.icon = routers[i].icon;
  //           let routerObj = {
  //             path: routers[i].path,
  //             name: routers[i].name,
  //             meta: meta,
  //             component(resolve) {
  //               if (component.startsWith("index")) { //src/layout/index.vue
  //                 require(['../../layout/index.vue'], resolve)
  //               }
  //             },
  //             hidden: routers[i].hidden,
  //           };
  //           if (routers[i].redirect != null) {
  //             routerObj.redirect = routers[i].redirect
  //           }
  //           let children = routers[i].children;
  //           let child = [];
  //           if (children.length > 0) {
  //             for (var k = 0; k < routers[i].children.length; k++) {
  //               let meta = {}
  //               if (routers[i].children[k].activeMenu != null) {
  //                 meta.activeMenu = routers[i].children[k].activeMenu
  //               }
  //               if (routers[i].children[k].breadcrumb != null) {
  //                 meta.breadcrumb = routers[i].children[k].breadcrumb
  //               }
  //               meta.title = routers[i].children[k].title
  //               meta.icon = routers[i].children[k].icon
  //               let childobj = {
  //                 path: routers[i].children[k].path,
  //                 name: routers[i].children[k].name,
  //                 meta: meta,
  //                 component() {
  //                   if (component.startsWith("index")) {
  //                     require(['' + component + '.vue'], resolve)
  //                   }
  //                 },
  //                 hidden: routers[i].children[k].hidden
  //               };
  //               if (routers[i].children[k].redirect != null) {
  //                 childobj.redirect = routers[i].children[k].redirect
  //               }
  //               child.push(childobj)

  //               let childrensj = routers[i].children[k].children;
  //               let childsj = [];
  //               if (childrensj.length > 0) {
  //                 for (var q = 0; q < childrensj.length; q++) {
  //                   let meta = {}
  //                   if (childrensj[q].activeMenu != null) {
  //                     meta.activeMenu = childrensj[q].activeMenu
  //                   }
  //                   if (childrensj[q].breadcrumb != null) {
  //                     meta.breadcrumb = childrensj[q].breadcrumb
  //                   }
  //                   meta.title = childrensj[q].title
  //                   meta.icon = childrensj[q].icon
  //                   let childobj = {
  //                     path: childrensj[q].path,
  //                     name: childrensj[q].name,
  //                     meta: meta,
  //                     component() {
  //                       if (component.startsWith("index")) {
  //                         require(['' + component + '.vue'], resolve)
  //                       }
  //                     },
  //                     hidden: childrensj[q].hidden
  //                   };
  //                   childsj.push(childobj)
  //                 }
  //                 childobj.children=childsj;
  //               }else{
  //                 childsj=[];
  //               }
  //               if(childsj.length>0){
  //                 child[k].children=childsj;
  //               }
  //             }
  //           } else {
  //             child = [];
  //           }
  //           if(child.length>0){
  //             routerObj.children = child;
  //           }
  //           routerList.push(routerObj);
  //         }
  //         routerList.push({ path: '*', redirect: '/404', hidden: true });
  //         // console.log("userroutlist:" + JSON.stringify(routerList));
  //         //  add到router中
  //         //  router.addRoutes(routerList); 
  //         //  resetRouter(routerList);
  //         //存储到vuex中
  //         commit('SET_ROLES', routerList);
  //         // console.log(routerList)
  //       }
  //       commit('SET_NAME', name)
  //       commit('SET_USERIDLG', useridlg)
  //       commit('SET_AVATAR', avatar)
  //       resolve(result)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(response => {
        if (response.status == 0) {
          removeToken() // must remove  token  first
          resetRouter()
          commit('RESET_STATE')
          commit('SET_ROLES', [])
          commit('SET_ROLELIST', [])
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      commit('SET_ROLES', [])
      commit('SET_ROLELIST', [])
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  // actions
}
